export function getNodeEnv() {
    return import.meta.env.NODE_ENV;
}

export function getNetlifyContext() {
    // used over NODE_ENV because NODE_ENV is always set to "development" by Netlify
    return import.meta.env.CONTEXT;
}

export function getNeltifyBranch() {
    return import.meta.env.BRANCH;
}

export function isProduction() {
    return getNetlifyContext() === "production";
}

export function isStaging() {
    return getNeltifyBranch() === "staging";
}

export function isProductionLike() {
    return isProduction() || isStaging();
}

export function isBranchDeploy() {
    return getNetlifyContext() === "branch-deploy";
}

export function isDeployPreview() {
    return getNetlifyContext() === "deploy-preview";
}

export function isDev() {
    return getNetlifyContext() === "dev";
}

export function isOnNetlify() {
    return Boolean(import.meta.env.NETLIFY);
}

export function isLocal() {
    return getNetlifyContext() === "local";
}

export function isDevelopmentLike() {
    return isDev() || isLocal() || isDeployPreview() || isBranchDeploy();
}

export function isTest() {
    // use NODE_ENV for vitest since ENVIRONMENT isn't available in vitest
    return getNodeEnv() === "test";
}

export function getWebsiteHostname() {
    return import.meta.env.PUBLIC_WEBSITE_HOSTNAME;
}

export function getWebsitePort() {
    return import.meta.env.PUBLIC_WEBSITE_PORT;
}

export function getWebsiteProtocol() {
    return import.meta.env.PUBLIC_WEBSITE_PROTOCOL;
}

export function getNetlifyBuildTimeUrl() {
    // seems like only available at build time, not at runtime (e.g. when running Netlify functions)
    return isOnNetlify() && isProduction() ? import.meta.env.URL : import.meta.env.DEPLOY_PRIME_URL;
}

export function getDeploymentUrl() {
    const host = getWebsiteHostname();
    const port = getWebsitePort();
    const protocol = getWebsiteProtocol();
    return new URL(`${protocol}://${host}${port ? `:${port}` : ""}`);
}

export function getSentryTraceSampleRate() {
    return parseFloat(import.meta.env.PUBLIC_SENTRY_TRACE_SAMPLERATE) ?? 1.0;
}

export function getSentryDsn() {
    return import.meta.env.PUBLIC_SENTRY_DSN;
}

export function getSentryEnabled() {
    const flag = import.meta.env.PUBLIC_SENTRY_ENABLED;
    return flag === "true" || flag === "1";
}

export function getSentryEnvironment() {
    return import.meta.env.PUBLIC_SENTRY_ENVIRONMENT ?? "development";
}
