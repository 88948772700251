<script lang="ts">
    /**
     * Integrate Sentry browser SDK.
     */
    import {onMount} from "svelte";
    import * as SentryBrowser from "@sentry/browser";
    import {getSentryDsn, getSentryEnabled, getSentryEnvironment, getSentryTraceSampleRate} from "@utils/env-utils";

    export let appVersion: string;

    onMount(() => {
        SentryBrowser.onLoad(() => {
            SentryBrowser.init({
                dsn: getSentryDsn(),
                enabled: getSentryEnabled(),
                environment: getSentryEnvironment(),
                tracesSampleRate: getSentryTraceSampleRate(),
                release: appVersion,
            });
            SentryBrowser.setUser(null);
        });
    });
</script>
